<template>
  <v-card flat>
    <v-card-text>
      <v-row dense class="fill-height" align="stretch">
        <v-col cols="12" order-md="2" order-sm="1">
          <v-form v-model="valid" class="px-md-5 px-sm-0">
            <v-row dense align="center" justify="center">
              <v-col cols="12" class="my-1">
                <label class="subtitle-2 primary--text">Top Up For:</label>
                <v-select
                  hide-details="auto"
                  :items="credit_types"
                  item-value="value"
                  item-text="name"
                  :rules="requiredRules"
                  v-model="credit_type"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" class="my-1">
                <label class="subtitle-2 primary--text">Top Up Amount:</label>
                <v-btn-toggle
                  v-model="charge_amount"
                  mandatory
                  dense
                  :class="{ 'flex-wrap': !mdAndUp }"
                  class="fullwidth mt-1"
                >
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="10"
                  >
                    {{ 10.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="50.00"
                  >
                    {{ 50.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="100.00"
                  >
                    {{ 100.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="500.00"
                  >
                    {{ 500.0 | money }}
                  </v-btn>
                  <v-btn
                    active-class="primary light--text"
                    class="text-none"
                    value="custom"
                  >
                    Custom
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" v-if="is_custom_amount">
                <label class="subtitle-2 primary--text">
                  Custom Charge amount:
                </label>
                <vuetify-money
                  outlined
                  clearable
                  dense
                  :disabled="processing"
                  :rules="[minMaxRule(2.0, 9999999.99)]"
                  value-when-is-empty="0.0"
                  v-model="custom_charge_amount"
                  class="fullwidth"
                  placeholder="Enter value"
                  :options="options_1"
                ></vuetify-money>
              </v-col>
              <v-col cols="12">
                <div class="d-flex fullwidth align-center justify-start">
                  <a
                    target="_blank"
                    href="https://www.paypal.com/us/webapps/mpp/merchant-fees"
                    class="text-none secondary--text fs-10 fw-500"
                  >
                    PayPal Fee Reference
                  </a>
                </div>
                <div
                  :class="{ disabled: btn_disabled }"
                  class="d-block"
                  id="paypal-buttons"
                ></div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import { loadScript } from '@paypal/paypal-js'
export default {
  name: 'PayPalPayment',
  props: {
    type: { type: String, default: 'top-up' }
  },
  data: () => ({
    valid: false,
    processing: false,
    is_custom_amount: false,
    charge_amount: 10.0,
    custom_charge_amount: 10.0,
    credit_types: [{ value: 'top-up', name: 'Wallet Top Up' }],
    options_1: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    credit_type: 'top-up'
  }),
  watch: {
    charge_amount(val) {
      this.is_custom_amount = val === 'custom'
    },
    type: {
      handler: function (val) {
        this.credit_type = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    btn_disabled() {
      if (this.processing || !this.valid) return true
      if (this.is_custom_amount)
        return !(
          this.custom_charge_amount >= 10 && this.custom_charge_amount <= 10000
        )
      return !(this.charge_amount >= 10 && this.charge_amount <= 10000)
    },
    payload() {
      return {
        credit_for: this.credit_type,
        amount: this.is_custom_amount
          ? this.custom_charge_amount
          : this.charge_amount
      }
    }
  },
  created() {
    const _this = this
    loadScript({
      'client-id': this.settings.paypal.client_id,
      currency: 'USD',
      debug: false
    })
      .then((paypal) => {
        paypal
          .Buttons({
            style: {
              layout: 'vertical',
              color: 'blue',
              shape: 'rect',
              label: 'pay'
            },
            createOrder: function (data, actions) {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: _this.payload.amount
                        ? parseFloat(_this.payload.amount).toFixed(2)
                        : 2.0
                    }
                  }
                ],
                application_context: {
                  shipping_preference: 'NO_SHIPPING'
                }
              })
            },
            onClick: function (data, actions) {
              if (_this.payload.amount) {
                _this.appSnackbar('Invalid amount', 'error')
                actions.reject()
                return
              } else if (_this.payload.amount < 2.0) {
                _this.appSnackbar('Amount should be atleast $2.00', 'error')
                actions.reject()
                return
              }
            },
            onApprove: function (data, actions) {
              _this.capturePayment(data)
            },
            onError: function (err) {
              console.log('Error!', err)
            }
          })
          .render('#paypal-buttons')
          .catch((error) => {
            console.error('failed to render the PayPal Buttons', error)
          })
      })
      .catch((error) => {
        console.error('failed to load the PayPal JS SDK script', error)
      })
  },
  methods: {
    capturePayment(data) {
      request.post(`api/credits/top-up/paypal`, data).then(({ data }) => {
        this.appSnackbar('Top-up via PayPal successfully completed')
        this.$emit('payment-success', data)
      })
    }
  }
}
</script>

<style></style>
