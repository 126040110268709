<template>
  <v-card flat>
    <v-card-text>
      <v-row dense class="fill-height" align="stretch">
        <v-col cols="12" order-md="2" order-sm="1">
          <v-form v-model="valid" class="px-md-5 px-sm-0">
            <v-row dense align="center" justify="center">
              <v-col cols="12" class="my-1">
                <label class="subtitle-2 primary--text">Top Up For:</label>
                <v-select
                  hide-details="auto"
                  :items="credit_types"
                  item-value="value"
                  item-text="name"
                  :rules="requiredRules"
                  v-model="credit_type"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" class="my-1">
                <label class="subtitle-2 primary--text">Top Up Amount:</label>
                <v-btn-toggle
                  v-model="charge_amount"
                  mandatory
                  dense
                  :class="{ 'flex-wrap': !mdAndUp }"
                  class="fullwidth mt-1"
                >
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="10"
                  >
                    {{ 10.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="50"
                  >
                    {{ 50.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="100"
                  >
                    {{ 100.0 | money }}
                  </v-btn>
                  <v-btn
                    class="px-2"
                    depressed
                    active-class="primary light--text"
                    value="500"
                  >
                    {{ 500.0 | money }}
                  </v-btn>
                  <v-btn
                    active-class="primary light--text"
                    class="text-none"
                    value="custom"
                  >
                    Custom
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" v-if="is_custom_amount">
                <label class="subtitle-2 primary--text">
                  Custom Charge amount:
                </label>
                <vuetify-money
                  outlined
                  clearable
                  dense
                  :disabled="processing"
                  :rules="[minMaxRule(2.0, 9999999.99)]"
                  value-when-is-empty="0.0"
                  v-model="custom_charge_amount"
                  class="fullwidth"
                  placeholder="Enter value"
                  :options="options_1"
                ></vuetify-money>
              </v-col>
              <!-- <v-col cols="12">
                <v-radio-group v-model="payment_method_type" row mandatory>
                  <label class="d-flex align-center justify-start">
                    <v-radio class="mx-1" value="new-card"> </v-radio>
                    <span class="primary--text fw-500">Use new card</span>
                  </label>
                  <label class="d-flex ml-2 align-center justify-start">
                    <v-radio
                      class="mx-1"
                      :disabled="no_default_card"
                      value="default-card"
                    ></v-radio>
                    <span class="primary--text fw-500">Use default card</span>
                  </label>
                </v-radio-group>
              </v-col> -->
              <v-col cols="12" v-show="payment_method_type === 'new-card'">
                <label class="primary--text subtitle-2">Payee Details</label>
                <v-row dense v-if="payment_method_type === 'new-card'">
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">First Name</label>
                    <v-text-field
                      v-model="customer.first_name"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">Last Name</label>
                    <v-text-field
                      v-model="customer.last_name"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-if="payment_method_type === 'new-card'">
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">Email</label>
                    <v-text-field
                      v-model="customer.email"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">Address</label>
                    <v-text-field
                      v-model="customer.address"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-if="payment_method_type === 'new-card'">
                  <v-col :cols="mdAndUp ? 6 : 12">
                    <label class="primary--text subtitle-2">City</label>
                    <v-text-field
                      v-model="customer.city"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="mdAndUp ? 3 : 12">
                    <label class="primary--text subtitle-2">State</label>
                    <v-text-field
                      v-model="customer.state"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="mdAndUp ? 3 : 12">
                    <label class="primary--text subtitle-2">
                      Postal Code
                    </label>
                    <v-text-field
                      v-model="customer.zip_code"
                      dense
                      outlined
                      hide-details="auto"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <label for="card-element" class="primary--text subtitle-2">
                      Card Details
                    </label>
                    <div id="card-element"></div>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-alert v-if="processing" class="fullwidth" type="warning">
                    <span class="fw-500">
                      Processing payment... Please wait...
                    </span>
                    <v-icon color="white" right>mdi-spin mdi-loading</v-icon>
                  </v-alert>
                  <v-btn
                    block
                    :disabled="btn_disabled"
                    :loading="processing"
                    color="primary"
                    @click="processChargeViaNewCard"
                    large
                    class="text-none my-2"
                  >
                    Charge {{ payload.amount | money }}
                  </v-btn>
                  <div
                    class="d-flex fullwidth align-center justify-space-between"
                  >
                    <span class="secondary--text fs-10 fw-500">
                      Stripe Fee (2.9% + $0.30) : {{ stripeFee | money }}
                    </span>
                    <span class="secondary--text fs-10 fw-500">
                      Net Amount: {{ netAmount | money }}
                    </span>
                  </div>
                </v-row>
                <v-row
                  dense
                  align="center"
                  justify="center"
                  class="fs-12 my-2 flex-nowrap fw-900 grey--text"
                >
                  <span>Powered by</span>
                  <v-img
                    class="ml-3 mr-auto"
                    height="20"
                    max-width="40"
                    contain
                    :src="require('@/assets/appIcons/stripe-icon.svg').default"
                  ></v-img>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                v-show="payment_method_type === 'default-card'"
                v-if="payment_method"
              >
                <h5 class="mb-1 subtitle-2 primary--text">Card Details</h5>
                <label class="primary--text subtitle-2">Email</label>
                <v-text-field
                  outlined
                  hide-details="auto"
                  class="mb-1"
                  readonly
                  dense
                  :value="payment_method.user.email"
                ></v-text-field>
                <label class="primary--text subtitle-2">Card Brand</label>
                <v-text-field
                  outlined
                  hide-details="auto"
                  class="mb-1"
                  readonly
                  dense
                  :value="payment_method.card.brand"
                ></v-text-field>
                <label class="primary--text subtitle-2">Card Last 4</label>
                <v-text-field
                  outlined
                  hide-details="auto"
                  class="mb-1"
                  dense
                  readonly
                  :value="`**** **** **** ${payment_method.card.last4}`"
                ></v-text-field>

                <v-btn
                  block
                  :disabled="btn_disabled"
                  :loading="processing"
                  color="primary"
                  @click="processChargeViaDefaultCard"
                  large
                  class="text-none my-2"
                >
                  Charge {{ payload.amount | money }}
                </v-btn>
                <div
                  class="d-flex fullwidth align-center justify-space-between"
                >
                  <span class="secondary--text fs-10 fw-500">
                    Stripe Fee (2.9% + $0.30) : {{ stripeFee | money }}
                  </span>
                  <span class="secondary--text fs-10 fw-500">
                    Net Amount: {{ netAmount | money }}
                  </span>
                </div>
                <v-row
                  dense
                  align="center"
                  justify="center"
                  class="fs-12 my-2 flex-nowrap fw-900 grey--text"
                >
                  <span>Powered by</span>
                  <v-img
                    class="ml-3 mr-auto"
                    height="20"
                    max-width="40"
                    contain
                    :src="require('@/assets/appIcons/stripe-icon.svg').default"
                  ></v-img>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { loadStripe } from '@stripe/stripe-js'
import request from '@/services/axios_instance'

export default {
  name: 'ChargeCredit',
  props: {
    type: { type: String, default: 'top-up' }
  },
  data: () => ({
    valid: false,
    processing: false,
    is_custom_amount: false,
    charge_amount: 10.0,
    custom_charge_amount: 10.0,
    options: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    credit_types: [ 
      { value: 'top-up', name: 'Wallet Top Up' }
    ],
    options_1: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    credit_type: 'top-up',
    payment_method_type: 'new-card',
    payment_method_id: null,
    stripe: {},
    cardElement: {},
    customer: {
      first_name: null,
      last_name: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zip_code: null
    }
  }),
  created() { 
    this.getCurrentSubscriptions()
    if (this.$route.params.type) {
      this.credit_type = this.$route.params.type
    }
  },
  async mounted() {
    this.stripe = await loadStripe(this.settings.stripe.pub_key)
    const element = this.stripe.elements()
    this.cardElement = element.create('card', {
      hidePostalCode: true,
      classes: {
        base: 'py-3 px-2 rounded-10 border-1 border-color--grey'
      },
      style: {
        base: {
          iconColor: '#3c599e',
          color: '#3c599e',
          fontWeight: '700',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883'
          },
          '::placeholder': {
            color: '#3c599e'
          }
        },
        invalid: {
          iconColor: '#e60914',
          color: '#e60914'
        }
      }
    })
    this.cardElement.mount('#card-element')
    this.mapUser()
  },
  watch: {
    charge_amount(val) {
      this.is_custom_amount = val === 'custom'
    },
    type: {
      handler: function (val) {
        this.credit_type = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('subscription', [ 
      'payment_method'
    ]),
    stripeFee() {
      return this.payload.amount ? (this.payload.amount * 2.9) / 100 + 0.3 : 0.0
    },
    netAmount() {
      return this.payload.amount ? this.payload.amount - this.stripeFee : 0.0
    },
    btn_disabled() {
      if (this.processing || !this.valid) return true
      if (this.is_custom_amount)
        return !(
          this.custom_charge_amount >= 10 && this.custom_charge_amount <= 10000
        )
      return !(this.charge_amount >= 10 && this.charge_amount <= 10000)
    },
    payload() {
      return {
        credit_for: this.credit_type,
        amount: this.is_custom_amount
          ? this.custom_charge_amount
          : this.charge_amount,
        payment_method_type: this.payment_method_type, //default-card or new-card
        payment_method_id: this.payment_method ? this.payment_method.id : null
      }
    },
    no_default_card() {
      return !this.payment_method
    }
  },
  methods: {
    ...mapActions('subscription', [ 
      'getCurrentSubscriptions'
    ]), 
    mapUser() {
      this.customer = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        address: this.user.address.line1 || '',
        city: this.user.address.city || '',
        state: this.user.address.state || '',
        zip_code: this.user.address.postal_code || ''
      }
    },
    async processChargeViaNewCard() {
      this.processing = true
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        'card',
        this.cardElement,
        {
          billing_details: {
            name: this.customer.first_name + ' ' + this.customer.last_name,
            email: this.customer.email,
            address: {
              line1: this.customer.address,
              city: this.customer.city,
              state: this.customer.state,
              postal_code: this.customer.zip_code
            }
          }
        }
      )
      if (error) {
        this.processing = false
        this.appSnackbar(error, 'error')
      } else {
        this.processing = true
        request
          .post(
            `api/credits/${this.credit_type}/new-card`,
            Object.assign({}, this.payload, {
              customer: this.customer,
              payment_method_id: paymentMethod.id
            })
          )
          .then(({ data }) => { 
            this.appSnackbar('Charge success')
            this.$emit('payment-success', data)
          })
          .catch((error) => {
            this.appSnackbar(error, 'error')
          })
          .finally(() => (this.processing = false))
      }
    },
    processChargeViaDefaultCard() {
      this.processing = true
      request
        .post(`api/credits/${this.credit_type}/default-card`, this.payload)
        .then(({ data }) => { 
          this.processing = false
          this.appSnackbar('Charge success')
          this.$emit('payment-success', data)
        })
        .catch((error) => {
          this.processing = false
          this.appSnackbar(error, 'error')
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
