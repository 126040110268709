<template>
  <v-card flat class="fullwidth">
    <v-card-text>
      <v-row dense align="stretch" justify="start" class="fill-height">
        <v-col class="d-flex" :cols="mdAndUp ? 6 : 12">
          <v-card
            flat
            outlined
            class="rounded-10 fullwidth"
            :style="mdAndUp ? 'min-height:100vh;' : ''"
          >
            <v-card-title
              class="primary--text fw-800 fs-24 d-flex align-center justify-space-between"
            >
              <span class="">Top Up</span>
              <v-btn
                class="text-none"
                depressed
                color="primary"
                v-if="$route.query.redirect_uri"
                @click="$router.push({ path: $route.query.redirect_uri })"
              >
                Back
              </v-btn>
            </v-card-title>
            <v-card-text
              class="d-flex fullwidth align-center justify-center flex-column"
            >
              <v-btn-toggle v-model="pay_by" mandatory>
                <v-btn
                  class="text-none"
                  :value="pay.value"
                  v-for="pay in pay_by_items"
                  :key="pay.value"
                >
                  <v-avatar tile height="40" width="80">
                    <v-img :title="pay.text" contain :src="pay.icon" />
                  </v-avatar>
                </v-btn>
              </v-btn-toggle>
              <CardPayment
                v-if="pay_by === 'stripe'"
                :key="componentKey"
                :type="type"
                @payment-success="refetch"
              ></CardPayment>
              <PayPalPayment
                v-else-if="pay_by === 'paypal'"
                :key="componentKey"
                :type="type"
                @payment-success="refetch"
              >
              </PayPalPayment>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex" :cols="mdAndUp ? 6 : 12">
          <VueTable
            title="Top Up History"
            :show-select="false"
            :loading="loading_items"
            :headers="headers"
            :items="items"
            empty-text="No history yet"
            @load-more="loadMoreItems"
            :no-more-data="!has_more_items"
            :style="mdAndUp ? 'min-height:100vh;' : ''"
            class="fullwidth fill-height"
          >
            <template slot="header-toolbar">
              <v-btn
                class="text-none"
                color="primary"
                depressed
                @click="
                  $router.push({
                    name: 'wallet-history',
                    query: { redirect_uri: $route.fullPath }
                  })
                "
              >
                Wallet History
              </v-btn>
            </template>
            <template v-slot:row-slot="{ item }">
              <td>{{ item.created_at | format('lll') }}</td>
              <td>{{ item.status | ucwords }}</td>
              <td>{{ item.amount | money }}</td>
              <td>{{ item.total_amount | money }}</td>
              <td><HelpToolTip :text="item.txid"></HelpToolTip></td>
            </template>
          </VueTable>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { fetch_services } from '@/services/fetch/fetch_services'
import CardPayment from '../components/CardPayment.vue'
import PayPalPayment from '../components/PayPalPayment.vue'
export default {
  components: { VueTable, CardPayment, PayPalPayment },
  mixins: [fetch_services],
  data: () => ({
    componentKey: 0,
    cancelling: false,
    fetching: false,
    type: 'top-up',
    options_1: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    headers: [
      { text: 'Date', align: 'left', sortable: false },
      { text: 'Status', align: 'left', sortable: false },
      { text: 'Amount', align: 'left', sortable: false },
      { text: 'Net Amount', align: 'left', sortable: false },
      { text: 'TXID', value: 'txid', align: 'center', sortable: false }
    ],
    pay_by: 'stripe',
    pay_by_items: [
      {
        text: 'Stripe',
        value: 'stripe',
        icon: require('@/assets/appIcons/stripe-icon.svg').default
      },
      {
        text: 'PayPal',
        value: 'paypal',
        icon: require('@/assets/appIcons/paypal-icon.svg').default
      }
    ]
  }),
  watch: {
    '$route.query.for': {
      handler: function (val) {
        if (val) this.type = val
      },
      immediate: true
    }
  },
  created() {
    this.loadItems(
      `api/wallet/history/transactions?from=top-up`,
      true,
      null,
      null
    )
  },
  methods: {
    ...mapActions('wallet', ['getWallet']),
    refetch() {
      this.getWallet()
      this.componentKey++
    }
  }
}
</script>

<style></style>
